import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  fill: "#1d1d1b",
  viewBox: "0 0 500 500"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { stroke: "currentColor" }, [
      _createElementVNode("path", { d: "M250.2 85.9h192.4c7.3 0 8 .6 8 7.9 0 13.6.1 27.2-.1 40.8 0 2.1-1.9 4.2-3 6.3-.6 1.3-1.7 2.6-1.7 3.9-.2 6.1 0 12.1-.1 18.2-.3 10-4.3 18.4-12.6 24.1-3.4 2.4-4.2 4.9-4.2 8.7.1 68.1.1 136.2.1 204.3 0 2.1 0 4.2-.2 6.2-.6 4.5-3.5 7-8 7.7-1.7.2-3.5.1-5.3.1h-331c-11 0-13.4-2.4-13.4-13.6V194.8c0-3.1-.6-5.2-3.4-7.1-8.8-5.9-13.1-14.6-13.3-25.2-.1-4.9 0-9.9 0-14.9 0-3.1.8-6.3-3.4-7.9-1-.4-1.4-3.1-1.4-4.7-.1-14.2-.1-28.5 0-42.7 0-5.3 1.1-6.4 6.5-6.4zM115.3 405.1v-122c0-6.3.9-7.2 7.2-7.2h62.4c7.5 0 8.3.8 8.3 8.2V405h226.6V253.4H80.2v151.7zM441.7 95H58.4v36.8h383.4c-.1-12.5-.1-24.5-.1-36.8zm-21.9 124.2H80.1V244h339.7zm-295.3 65.7V405h59.6V284.9zM419.8 193c-13.7 1.4-25.1-2.5-33.1-14.1-13.8 19.5-42.1 18.6-54.6.2-14.1 19.7-42.5 18-54.9-.2-6.6 9.5-15.7 14.3-27.3 14.3-11.5 0-20.5-4.9-27.3-14.5-6.9 9.8-16 14.5-27.6 14.5-11.5 0-20.4-5.1-27.1-14.4-11.9 18-40.4 20.3-54.8.1-7.9 11.8-19.4 15.3-32.9 14.1v16.6h339.6zm-302-51.5c0 7.5-.1 14.5 0 21.5.2 12 8.9 20.7 21.3 21.4 12.3.8 22.8-6.8 24-18.4.9-8 .2-16.2.2-24.5zm264.1.2h-45.5c0 7.6-.2 14.7 0 21.9.3 11.6 9.3 20.2 21.5 20.9 12.4.7 22.8-6.9 23.9-18.6.8-7.9.1-15.9.1-24.2zm-100.1-.2c0 8.2-.5 16.1.1 23.8.9 11 10 18.6 21.6 19.1s21.7-6.2 23.2-17.1c1.1-8.4.2-17.1.2-25.8zm-218.6 0c0 8.1-.5 15.6.1 23.1.9 11.1 9.2 18.7 20.5 19.8 11.2 1 21.8-4.8 23.8-15 1.8-9 1.2-18.5 1.7-27.9zm155 .1h-45.6c0 8.3-.6 16.3.1 24.2 1.2 11.5 11.3 19.1 23.4 18.7 12-.4 21.2-8.6 21.9-19.9.6-7.5.2-15 .2-23zm54.3 0h-45.4c0 7.1-.1 13.9 0 20.8.1 12.1 8.4 20.9 20.8 22 12.3 1.1 23-6.1 24.5-17.8 1-8.2.1-16.5.1-25zm118.6-.2c0 7.6-.1 14.6 0 21.6.1 10.8 7.8 19.4 18.6 21 12.1 1.9 23.5-3.8 25.8-14.6 1.9-9 1.4-18.5 2-28z" }),
      _createElementVNode("path", { d: "M289.8 327.5v44.6c0 6-1 7-7 7h-56.1c-5.9 0-6.9-1.1-6.9-7.1v-89.1c0-5.9 1.2-7 7-7h56.1c5.9 0 6.9 1 6.9 7.1zm-9.3 42.4v-85H229v85zm43.1-42.7v-44.6c0-5.8 1-6.8 6.6-6.8h56.6c5.5 0 6.8 1.2 6.8 6.8v89.6c0 5.6-1.3 6.8-6.8 6.8h-56.6c-5.7 0-6.7-1.1-6.7-6.8 0-14.9.1-29.9.1-45zm9.1-42.3v84.9h51.7v-84.9zm-157.5 59.9c0 2.9.5 5.9-.2 8.5-.4 1.7-2.5 3-3.9 4.5-1.5-1.5-4.3-2.8-4.4-4.5-.5-5.7-.5-11.5 0-17.1.1-1.5 2.7-3.9 4.1-4 1.4 0 3.7 2.3 4.1 3.9.8 2.9.3 5.9.3 8.7z" })
    ], -1)
  ])))
}
export default { render: render }